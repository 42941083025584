import React, { useState } from 'react';

export const WbnOrganizationContext = React.createContext();

const WbnOrganizationProvider = props => {
    const [namePl, setNamePl] = useState('');
    const [nameEn, setNameEn] = useState('');
    const [authenticated, setAuthenticated] = useState('');

    return (
        <WbnOrganizationContext.Provider
            value={{ namePl, setNamePl, nameEn, setNameEn, authenticated, setAuthenticated }}
        >
            {props.children}
        </WbnOrganizationContext.Provider>
    );
};

export default WbnOrganizationProvider;

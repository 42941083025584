import React, { useContext }  from "react";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';
import {useTranslation} from 'react-i18next';
import Button from 'react-bootstrap/Button'
import LanguageForm from './LanguageForm';
import ScientificDisciplineForm from './ScientificDisciplineForm';
import LicenseForm from './LicenseForm';
import PublishingCompanyAndJournalForm from './PublishingCompanyAndJournalForm';
import KeywordForm from './KeywordForm';
import { convertUrlQueryValuesToFormValues } from './AdvancedSearchUtils';
import { WbnOrganizationContext } from '../../common/WbnOrganizationProvider';

/**
 * Renders the advanced search criteria form fields. Expects `formik` prop.
 *
 * @author sniewcza
 */
export default function AdvancedSearchForm({formik, api}) {
  const {t} = useTranslation();
  const { authenticated, setAuthenticated } = useContext(WbnOrganizationContext);

  return (
    <>

      { authenticated &&
        <Form.Group as={Row}>
          <Col sm={12}>
            <Form.Check id="with-open-resources" type='checkbox' checked={formik.values.openResources} {...formik.getFieldProps('openResources')}/>
            <Form.Label htmlFor="with-open-resources" className="text-normalcase">{t('search.form.advanced.open-resources.label')}</Form.Label>
          </Col>
        </Form.Group>
      }

      <Form.Group>
        <Form.Row as={Row}>
          <Form.Label htmlFor="contributors" column sm={12}>{t('search.form.advanced.contributors.label')}</Form.Label>
          <Col sm={12}><FormControl id="contributors" type="text" {...formik.getFieldProps('contributors')}
                                              placeholder={t('search.form.advanced.contributors.placeholder')}/></Col>
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <Form.Row as={Row} className="search-form-multiple-inputs-line">
          <Col sm={6}>
            <Form.Label htmlFor="published-date-since" column sm={12}>{t('search.form.advanced.published-date-since.label')}</Form.Label>
            <Col sm={12}>
              <FormControl id="published-date-since"
                           aria-describedby="published-date-since-errors"
                           type="text"
                           placeholder={t('search.form.advanced.published-date.placeholder')}
                           isInvalid={formik.touched.publishedDateFrom && formik.errors.publishedDateFrom}
                           {...formik.getFieldProps('publishedDateFrom')}/>
              <Form.Control.Feedback id="published-date-since-errors" type="invalid">{formik.errors.publishedDateFrom}</Form.Control.Feedback>
            </Col>
          </Col>
          <Col sm={6}>
            <Form.Label htmlFor="published-date-to" column sm={12}>{t('search.form.advanced.published-date-to.label')}</Form.Label>
            <Col sm={12}>
              <FormControl id="published-date-to"
                           aria-describedby="published-date-to-errors"
                           type="text"
                           placeholder={t('search.form.advanced.published-date.placeholder')}
                           isInvalid={formik.touched.publishedDateTo && formik.errors.publishedDateTo}
                           {...formik.getFieldProps('publishedDateTo')}/>
              <Form.Control.Feedback id="published-date-to-errors" type="invalid">{formik.errors.publishedDateTo}</Form.Control.Feedback>
            </Col>
          </Col>
        </Form.Row>
      </Form.Group>

      <Form.Group as={Row}>
        <Col sm={12}>
          <Form.Check id="with-research-data-link" type='checkbox' checked={formik.values.researchDataLink} {...formik.getFieldProps('researchDataLink')}/>
          <Form.Label htmlFor="with-research-data-link" className="text-normalcase">{t('search.form.advanced.research-data-link.label')}</Form.Label>
        </Col>
      </Form.Group>

      <Form.Group>
        <Form.Row as={Row}>
          <Form.Label htmlFor="language" column sm={12}>{t('search.form.advanced.language.label')}</Form.Label>
          <Col sm={12}><LanguageForm inputId="language" formik={formik} api={api}/></Col>
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <Form.Row as={Row}>
          <Form.Label htmlFor="license" column sm={12}>{t('search.form.advanced.licenses.label')}</Form.Label>
          <Col sm={12}><LicenseForm inputId="license" formik={formik} api={api}/></Col>
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <Form.Row as={Row}>
          <Form.Label htmlFor="scientificDisciplines" column sm={12}>{t('search.form.advanced.scientific-disciplines.label')}</Form.Label>
          <Col sm={12}><ScientificDisciplineForm inputId="scientificDisciplines" formik={formik} api={api}/></Col>
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <Form.Row as={Row}>
          <Form.Label htmlFor="keywords" column sm={12}>{t('search.form.advanced.keywords.label')}</Form.Label>
          <Col sm={12}><KeywordForm inputId="keywords" formik={formik} api={api}/></Col>
        </Form.Row>
      </Form.Group>

      <Form.Group>
        <Form.Row as={Row}>
          <Form.Label htmlFor="pub-company-journal" column sm={12}>{t(formik.values.scope === 'books' ? 'search.form.advanced.pcj.label.publisher' : 'search.form.advanced.pcj.label.full')}</Form.Label>
          <Col sm={12}><PublishingCompanyAndJournalForm inputId="pub-company-journal" formik={formik} api={api}/></Col>
        </Form.Row>
      </Form.Group>

      <Row>
        <Col xs={12} sm={6} className="search-form-clear">
          <Button onClick={() => formik.resetForm({values: convertUrlQueryValuesToFormValues({})})} variant="outline-primary">{t('search.form.simple.button.clear.label')}</Button>
        </Col>
        <Col xs={12} sm={6} className="search-form-submit">
          <Button type="submit" variant="primary">{t('search.form.simple.button.submit.label')}</Button>
        </Col>
      </Row>
    </>
  );
}

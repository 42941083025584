import React from 'react';
import ReactDOM from 'react-dom';
import './i18n/i18n';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'quill/dist/quill.core.css'
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { createApiInstance } from './common/ApiConfig';
import WbnOrganizationProvider from './common/WbnOrganizationProvider';

const matomoInstance = createInstance({
  urlBase: 'https://piwik.vls.icm.edu.pl/',
  siteId: 37,
  linkTracking: false
})

ReactDOM.render(
  <MatomoProvider value={matomoInstance}>
    <HelmetProvider>
      <BrowserRouter>
        <WbnOrganizationProvider>
          <App api={createApiInstance()}/>
        </WbnOrganizationProvider>
      </BrowserRouter>
    </HelmetProvider>
  </MatomoProvider>,
  document.getElementById('root')
);

import React from 'react';
import './ContributorPublicationsLink.css';
import {useTranslation} from 'react-i18next';


export default function ContributorPublicationsLink({contributor, type}) {
  const { t } = useTranslation();

  return (
    <div>
      <a
        href={buildAllPublicationsLink(contributor, type)}
        target="_blank"
        rel="noopener noreferrer"
        className="all-publications-link"
      >
        <strong>{t('details.publication.contributors.publications')}</strong>
      </a>
    </div>
  )
}

// -------------------- PRIVATE --------------------

function buildAllPublicationsLink(contributor, type) {
  const defaultSearchPath = '/search'
  if (type === 'name') {
    const firstName = contributor.firstName ?? '';
    const lastName = contributor.lastName ?? '';
    const contributors = [firstName, lastName].filter(Boolean).join('%20');

    return contributors ? `/search?contributors=${contributors}` : defaultSearchPath;
  } else if (type === 'orcid') {
    const orcid = contributor.orcid ?? '';
    return orcid ? `/search?contributors=${contributor.orcid}` : defaultSearchPath;
  }

  return defaultSearchPath;
}

import React, { useContext } from "react";
import { withRouter } from 'react-router-dom';
import { isEmptyOrContainsOnlyNulls, decodeBase64 } from '../common/utils';
import { WbnOrganizationContext } from "../common/WbnOrganizationProvider";

/**
 * Higher order component implementing the error handling on the pre-configured
 * Axios instance. Takes care of the following situations:
 *
 *  - REST responds with 404 status (the application redirects to /not-found)
 *  - REST responds with status other than 200 and 404 (the application
 *    redirects to /error)
 *  - connection error occurs (the application redirects to /error)
 *
 * This is achieved by adding interceptor to the pre-configured axios instance.
 * Interceptors check the response and redirect if necessary. The solution has
 * a form of a higher order component, since access to browser history is
 * necessary to do the redirect, and history object can only be provided as
 * a component prop.
 *
 * Errors handled somewhere else:
 *
 * - Routes without an exact match are matched to the wildcard route defined in
 *   App component. This route is assigned to the NotFoundError component
 * - Errors arising from programming mistakes not related to REST requests are
 *   left to be handled by the ErrorBoundary component, which will, in turn,
 *   render the GeneralError component.
 */
const withErrorHandling = WrappedComponent => {

  function ErrorHandler(props) {
    const { namePl, setNamePl, nameEn, setNameEn, authenticated, setAuthenticated } = useContext(WbnOrganizationContext);

    if (isEmptyOrContainsOnlyNulls(props.api.interceptors.response.handlers)) {
      const onResponseFulfilled = response => {
        if (response.headers && response.headers["x-organization-name-pl"] && response.headers["x-organization-name-en"]) {
          setNamePl(decodeBase64(response.headers["x-organization-name-pl"]));
          setNameEn(decodeBase64(response.headers["x-organization-name-en"]));
          setAuthenticated(response.headers["x-organization-authenticated"]);
        }
        return response;
      }
      const onResponseRejected = error => {
        if (error.response) {
          switch (error.response.status) {
            case 404:
              props.history.push('/not-found');
              break;
            case 403:
              props.history.push('/access-denied');
              break;
            default:
              props.history.push('/error');
              break;
          }
        } else {
          props.history.push('/error');
        }

        return Promise.reject(error);
      };

      props.api.interceptors.response.use(onResponseFulfilled, onResponseRejected);
    }

    if (isEmptyOrContainsOnlyNulls(props.api.interceptors.request.handlers)) {
      const onRequestFulfilled = config => config;

      const onRequestRejected = error => {
        props.history.push('/error');
        return Promise.reject(error);
      };

      props.api.interceptors.request.use(onRequestFulfilled, onRequestRejected);
    }

    return <WrappedComponent {...props} />;
  }

  return withRouter(ErrorHandler);
};

export default withErrorHandling


export const en = {
  translation: {
    "logo.main": "Library of Science",
    "logo.main.alt": "Library of Science logo",
    "header.skiptocontent": "Skip to content",
    "header.gotositemap": "Go to Sitemap",
    "navbar.menuitem.resources": "Resources",
    "navbar.menuitem.aboutproject": "About",
    "navbar.menuitem.help": "Help",
    "navbar.menuitem.contact": "Contact",
    "navbar.menuitem.forpublishers": "For publishers",
    "navbar.menuitem.joinpublishers": "Join publishers",
    "navbar.menuitem.faq": "FAQ",
    "navbar.menuitem.login": "Login",
    "navbar.menuitem.privacypolicy": "Privacy Policy",
    "navbar.menuitem.accessibility": "Accessibility",
    "navbar.menuitem.termsandconditions": "Terms of Use",
    "navbar.menuitem.reporterror": "Report error",
    "navbar.menuitem.sitemap": "Sitemap",
    "navbar.menuitem.switchlanguage.tooltip": "Change website language",
    "navbar.menuitem.switchlanguage.current.pl": "Currently Polish",
    "navbar.menuitem.switchlanguage.current.en": "Currently English",
    "navbar.menuitem.switchlanguage.button.label.pl": "PL",
    "navbar.menuitem.switchlanguage.button.labellong.pl": "Polski",
    "navbar.menuitem.switchlanguage.button.tooltip.pl": "Zmień język witryny na polski",
    "navbar.menuitem.switchlanguage.button.label.en": "EN",
    "navbar.menuitem.switchlanguage.button.labellong.en": "English",
    "navbar.menuitem.switchlanguage.button.tooltip.en": "Change website language to English",
    "navbar.flag.alt": "Language country flag",
    "breadrumb.aria-label": "Breadcrumbs",
    "cookies-banner.text": "We use cookies. You can set the conditions for storing and accessing cookies in your browser.",
    "cookies-banner.learnmore": "Learn more",
    "cookies-banner.button.close": "Close",
    "footer.copyright": "Copyright",
    "footer.logo.alt.uw": "University of Warsaw",
    "footer.logo.alt.icm": "Interdisciplinary Centre for Mathematical and Computational Modelling at University of Warsaw",
    "footer.logo.alt.fe": "European Funds",
    "footer.logo.alt.rp": "The Republic of Poland",
    "footer.logo.alt.ue": "European Union",
    "meta.site-name": "Library of Science",
    "meta.home.title": "Home",
    "meta.home.description": "Search and read thousands of scientific publications for free! The biggest Open Access collection of scientific articles and books in Poland.",
    "meta.default.title": "Library of Science",
    "meta.default.description": "Search and read thousands of scientific publications for free! The biggest Open Access collection of scientific articles and books in Poland.",
    "meta.publishers.title": "Publishers",
    "meta.publishers.description": "List of publishers cooperating with ICM UW that share scientific publications in the Library of Science",
    "meta.journals.title": "Journals",
    "meta.journals.description": "List of journals available in the Library of Science",
    "meta.books.title": "Books",
    "meta.books.description": "List of books available in the Library of Science",
    "meta.journal.issues.description": "Issues",
    "welcome.title": "Library of Science",
    "welcome.description": "Library of Science - home page",
    "welcome.search.backgroundVideo.play": "Play",
    "welcome.search.backgroundVideo.pause": "Pause",
    "welcome.stats.openAccess.header": "Open Access",
    "welcome.stats.restrictedAccess.header": "VLoS",
    "welcome.stats.restrictedAccess.tooltip": "Access for: {{organizationName}}; Virtual Library of Science archive: {{publisherNames}}",
    "welcome.scientificFields.all": "All",
    "welcome.scientificFields.all.ariaLabel": "Show all most recent/ most popular",
    "welcome.scientificFields.ariaLabelPostfix": "Show most recent/ most popular per scientific discipline",
    "welcome.newest.header": "Recent",
    "welcome.popular.header": "Most popular",
    "welcome.books.header": "Books",
    "welcome.books.empty": "No books",
    "welcome.books.emptyScientificField": "No books in selected scientific discipline",
    "welcome.books.loadingFailed": "Attempt to load books has failed.",
    "welcome.articles.header": "Articles",
    "welcome.articles.empty": "No articles",
    "welcome.articles.emptyScientificField": "No articles in selected scientific discipline",
    "welcome.articles.loadingFailed": "Attempt to load articles has failed.",
    "welcome.journals.header": "Journals",
    "welcome.journals.empty": "No journals",
    "welcome.journals.emptyScientificField": "No journals in selected scientific discipline",
    "welcome.journals.loadingFailed": "Attempt to load journals has failed.",
    "welcome.databases.header": "Databases",
    "welcome.publishers.header": "Publishers",
    "welcome.publications.show-all": "View all",
    "welcome.articles.show-all.ariaLabel": "View all articles",
    "welcome.books.show-all.ariaLabel": "View all books",
    "welcome.publishers.show-all": "View all",
    "welcome.publishers.show-all.ariaLabel": "View all publishers",
    "contact.title": "Contact",
    "contact.description": "Library of Science - contact",
    "contact.helpdesk.mail.label": "Feel free to contact us:",
    "about-project.title": "About the project",
    "about-project.description": "Library of Science - about",
    "join-publishers.title": "Join publishers",
    "join-publishers.description": "Library of Science - join publishers",
    "join-publishers.terms": "Terms of Use",
    "join-publishers.terms.download.ariaLabel": "Download Terms of Use",
    "join-publishers.agreement": "Agreement",
    "join-publishers.agreement.download.ariaLabel": "Download Agreement",
    "join-publishers.download": "Download",
    "sitemap.title": "Sitemap",
    "sitemap.description": "Library of Science - sitemap",
    "sitemap.home": "Home",
    "sitemap.search": "Search",
    "sitemap.resources": "Resources",
    "sitemap.publishingcompanies": "Publishers",
    "sitemap.journals": "Journals",
    "sitemap.books": "Books",
    "sitemap.about": "About",
    "sitemap.help": "Help",
    "sitemap.pubrodocumentation": "Documentation of publicly available part of the Platform",
    "sitemap.pueditdocumentation": "Documentation for logged in users",
    "sitemap.embeddingdocumentation": "Embedding the Library of Science on your own website",
    "sitemap.apidocumentation": "API documentation",
    "sitemap.contact": "Contact",
    "sitemap.joinpublishers": "Join publishers",
    "sitemap.faq": "FAQ",
    "sitemap.privacypolicy": "Privacy Policy",
    "sitemap.accessibility": "Accessibility",
    "sitemap.termsandconditions": "Terms of Use",
    "library.tab.publishing-companies": "Publishers",
    "library.tab.journals": "Journals",
    "library.tab.books": "Books",
    "journal.search.input.placeholder": "Search by title",
    "journal.search.button.title": "Search journals",
    "journal.list.title": "Journals",
    "journal.list.emptySearch": "No journals meeting search criteria",
    "book.search.input.placeholder": "Search by title",
    "book.search.button.title": "Search books",
    "book.list.title": "Books",
    "book.list.emptySearch": "No books meeting search criteria",
    "publishing.company.list.title": "Publishers",
    "publishing.company.list.empty": "No publishers",
    "publishing.company.list.emptySearch": "No publishers meeting search criteria",
    "publishing.company.search.input.placeholder": "Search by name",
    "publishing.company.search.button.title": "Search publishers",
    "publishing.company.details.header.publisher": "Publisher",
    "publishing.company.details.header.basicinfo": "General information",
    "publishing.company.details.header.journals": "Journals",
    "publishing.company.details.header.books": "Books",
    "publishing.company.details.additionalnames": "Additional names",
    "publishing.company.details.addresses": "Addresses",
    "publishing.company.details.phones": "Phone numbers",
    "publishing.company.details.emails": "E-mail addresses",
    "publishing.company.details.urls": "Publisher websites",
    "publishing.company.details.openaccesspolicy": "Open Access policy",
    "publishing.company.details.nodata": "No data",
    "publishing.company.details.latest.issue.header": "Latest issue",
    "publishing.company.details.latest.issue.prefix": "No.",
    "publishing.company.details.latest.book.header": "Latest book",
    "publishing.company.logo.alt": "Publisher logo",
    "publishing.company.logo.default.alt": "Publisher icon",
    "journal.details.section.header.basic-information": "Basic information",
    "journal.details.section.header.descriptions": "Journal description",
    "journal.details.section.header.scientific-fields": "Scientific disciplines",
    "journal.details.section.header.indexation": "Indexation and indicators",
    "journal.details.section.header.contact": "Contact",
    "journal.details.section.header.for-authors": "For authors and reviewers",
    "journal.details.section.header.issues": "Issues",
    "journal.details.header.journal": "Journal",
    "journal.details.header.issues": "Issues",
    "journal.details.header.maintitletranslations": "Title translations",
    "journal.details.header.additionaltitles": "Additional titles",
    "journal.details.header.description": "Description",
    "journal.details.header.descriptiontranslations": "Description translations",
    "journal.details.header.externalidentifiers": "External identifiers",
    "journal.details.header.additionaldescriptions": "Additional information",
    "journal.details.header.contact": "Contact details",
    "journal.details.header.links": "Journal websites",
    "journal.details.header.databases": "Journal indexing databases",
    "journal.details.header.scores": "Indicators",
    "journal.details.language.prefix": "Journal language",
    "journal.details.issuefrequency.prefix": "Publication frequency",
    "journal.details.last.issue": "Latest issue",
    "journal.details.last.issue.empty": "No issues",
    "journal.details.for-authors.empty": "No information for authors and reviewers.",
    "journal.details.contact.empty": "No contact information",
    "journal.details.indexation-and-scores.empty": "No info on indexation and indicators",
    "journal.details.latest.issue.header": "Latest issue",
    "journal.details.linked-journals": "Linked titles",
    "journal.list.empty": "No journals",
    "journal.database.list.empty": "No databases",
    "journal.database.journals.header": "Journals",
    "journal.database.name.AGRO": "AGRO",
    "journal.database.name.BAZ_EKON": "BazEkon",
    "journal.database.name.BAZ_TECH": "BazTech",
    "journal.database.name.CEJSH": "CEJSH",
    "journal.database.name.DML_PL": "DML-PL",
    "journal.database.name.PSJD": "PSJD",
    "journal.database.description.AGRO": "journals on natural and agricultural sciences",
    "journal.database.description.BAZ_EKON": "journals in economic sciences",
    "journal.database.description.BAZ_TECH": "journals in engineering and technology and exact sciences",
    "journal.database.description.CEJSH": "journals in humanities, social sciences, theology and arts",
    "journal.database.description.DML_PL": "journals in mathematics",
    "journal.database.description.PSJD": "journals in medical and health sciences",
    "issue.details.header.issue": "Issue",
    "issue.details.header.articles": "Articles",
    "issue.list.empty": "No issues",
    "issue.list.filter.year": "Year(s)",
    "issue.list.filter.year.all": "All issues",
    "issue.details.journal": "Journal",
    "issue.details.year": "Year",
    "issue.details.volume": "Volume",
    "issue.details.number": "Number",
    "issue.cover.alt": "Issue cover",
    "issue.cover.default.alt": "Issue icon",
    "article.list.empty": "No articles",
    "article.details.journalandissue.journal.title.prefix": "Journal:",
    "article.details.journalandissue.journal.issn.prefix": "ISSN:",
    "article.details.journalandissue.issue.year.prefix": "Year:",
    "article.details.journalandissue.issue.volume.prefix": "Volume:",
    "article.details.journalandissue.issue.number.prefix": "Number:",
    "article.details.pagerange.prefix": "Pages:",
    "article.details.number.prefix": "Article ID:",
    "article.details.kind": "Article",
    "book.list.empty": "No books",
    "book.details.header.tableofcontents": "Table of Contents",
    "book.details.header.description": "Description",
    "book.details.releaseplace.prefix": "Place of publication:",
    "book.details.releaseyear.prefix": "Publication date:",
    "book.details.pagecount.prefix": "Number of pages:",
    "book.details.kind.simplebook": "Monograph",
    "book.details.kind.collectivework": "Collective work",
    "book.cover.alt": "Book cover",
    "book.cover.default.alt": "Book icon",
    "bookparts.list.empty": "No book parts",
    "chapter.list.empty": "No chapters",
    "chapter.details.pageRange.prefix": "Pages:",
    "chapter.details.kind": "Chapter",
    "citation.button.cite": "Citation",
    "citation.header.title": "Citation",
    "citation.footer.button.bib.title": "BibTeX",
    "citation.footer.button.bib.extension": "(BiB)",
    "citation.footer.button.ris.title": "EndNote, RefMan, ProCite",
    "citation.footer.button.ris.extension": "(RIS)",
    "citation.footer.button.ariaLabel.prefix": "Download citation - format",
    "wbnAccessIcon.tooltip.ARTICLE": "Article",
    "wbnAccessIcon.tooltip.SIMPLE_BOOK": "Monograph",
    "wbnAccessIcon.tooltip.COLLECTIVE_WORK": "Collective work",
    "wbnAccessIcon.tooltip.CHAPTER": "Chapter",
    "wbnAccessIcon.tooltip.ISSUE": "Issue",
    "wbnAccessIcon.tooltip.JOURNAL": "Journal",
    "wbnAccessIcon.tooltip.PUBLISHER": "Publisher",
    "wbnAccessIcon.tooltip.UNKNOWN": "Publisher/publication",
    "wbnAccessIcon.tooltip": "from Virtual Library of Science, access for: ",
    "publication.details.previewmode": "Draft preview",
    "publication.details.title.header": "Title",
    "publication.details.non.book.abstracts.header": "Abstract",
    "publication.details.book.abstracts.header": "Summary",
    "publication.details.chapter.abstracts.header": "Summary",
    "publication.details.bibliography.header": "Bibliography",
    "publication.details.affiliations.header": "Affiliations",
    "publication.details.keywords.header": "Keywords",
    "publication.details.license.unknown": "No data",
    "publication.details.publicationattributes.header": "Publication features",
    "publication.details.language.prefix": "Main language of publication",
    "publication.details.remarks.header": "Additional information",
    "publication.details.index.header": "Index",
    "publication.details.researchdata.link": "Link to research data",
    "publication.details.scientificdisciplines.header": "Scientific disciplines",
    "publication.details.scientificdisciplines.link.tooltip": "Search for publications in the scientific discipline",
    "publication.details.attributes.reviewedpublication": "Peer-reviewed publication",
    "publication.details.attributes.postconferencepublication": "Conference paper",
    "publication.details.attributes.reprintpublication": "Reprint",
    "publication.details.attributes.translationpublication": "Translation",
    "publication.details.fulltext.accessibility.request": "Accessibility issue? Request a WCAG compliant file",
    "publication.details.diamondaccess.tooltip": "Diamond Open Access - journal is not charging authors for publishing in Open Access.",
    "details.publication.contributors.name": "Full name",
    "details.publication.contributors.publications": "All publications",
    "details.publication.contributors.bio": "Bio",
    "details.publication.contributors.email": "E-mail",
    "details.publication.contributors.degrees": "Title / academic degrees",
    "details.publication.contributors.affiliations": "Affiliations",
    "details.publication.contributors.orcid": "ORCID",
    "details.publication.contributors.corresponding": "Corresponding",
    "details.publication.contributors.corresponding.true": "YES",
    "details.publication.contributors.corresponding.false": "NO",
    "details.publication.contributors.section.noInfo": "No information",
    "details.publication.contributors.corresponding.asterisk": "*",
    "details.publication.contributors.more.tooltip": "Show all",
    "details.publication.contributors.more.tooltip.AUTHOR": "authors",
    "details.publication.contributors.more.tooltip.EDITOR": "editors",
    "details.publication.contributors.more.tooltip.SCIENTIFIC_EDITOR": "scientific editors",
    "details.publication.contributors.more.tooltip.REVIEWER": "reviewers",
    "details.publication.contributors.more.tooltip.TRANSLATOR": "translators",
    "details.publication.contributors.less.tooltip": "Collapse list",
    "contributor.modal.header": "Author information",
    "contributor.modal.button.close": "Close",
    "search.title": "Search",
    "search.description": "Search and read thousands of Open Access scientific publications! Service provided in cooperation with publishers.",
    "search.form.select.async.loading.message": "Loading...",
    "search.form.select.nooptions.message": "No options",
    "search.form.advanced.keyword.minimum.length.message": "Please enter at least three characters",
    "search.form.advanced.pcj.minimum.length.message": "Please enter at least three characters",
    "search.form.simple.stats.articles": "articles",
    "search.form.simple.stats.journals": "journals",
    "search.form.simple.stats.books": "books",
    "search.form.simple.stats.oncclicence": "publications under CC license",
    "search.form.simple.title.main": "Search the largest scientific collection in Poland",
    "search.form.simple.title.subtitle": "100% full-text publications",
    "search.form.simple.button.clear.label": "Clear",
    "search.form.simple.button.submit.label": "Search",
    "search.form.simple.scope.all": "All",
    "search.form.simple.scope.journals": "In journals",
    "search.form.simple.scope.books": "In books",
    "search.form.simple.placeholder": "Search words",
    "search.form.simple.advanced-search.link": "Advanced search",
    "search.form.in.publishing.company.input.placeholder": "Search publisher’s resources",
    "search.form.in.publishing.company.button.title": "Search publisher’s resources",
    "search.form.in.journal.input.placeholder": "Search in journal",
    "search.form.in.journal.button.title": "Search in journal",
    "search.result.list.header": "Search results",
    "search.result.list.total.label": "Number of results:",
    "search.result.list.empty": "No results",
    "search.result.sort.select.label": "Sort",
    "search.result.sort.score.asc": "by relevance - ascending",
    "search.result.sort.score.desc": "by relevance - descending",
    "search.result.sort.date.asc": "by date - ascending",
    "search.result.sort.date.desc": "by date - descending",
    "search.result.pagesize.select.label": "Results per page",
    "search.result.list.item.frame.article.header": "Article",
    "search.result.list.item.frame.simplebook.header": "Monograph",
    "search.result.list.item.frame.collectivework.header": "Collective work",
    "search.result.list.item.frame.chapter.header": "Chapter",
    "search.result.list.item.abstract.header": "Abstract",
    "search.result.list.item.summary.header": "Summary",
    "search.result.list.item.fulltext.header": "Full text",
    "search.result.list.item.download.label": "Download",
    "search.result.list.item.license.label": "Freedom of use",
    "search.result.list.item.scientificdisciplines.link.tooltip": "Search for publications with filter: scientific discipline",
    "search.result.list.item.contributors.link.tooltip": "Search for publications with filter: contributor",
    "search.pagination.first.ariaLabel": "First page of search results",
    "search.pagination.previous.ariaLabel": "Previous page of search results",
    "search.pagination.current.label": "(current page)",
    "search.pagination.page.ariaLabelPostfix": "search results page",
    "search.pagination.next.ariaLabel": "Next page of search results",
    "search.pagination.last.ariaLabel": "Last page of search results",
    "search.form.advanced.publication-type.label": "Publication type:",
    "search.form.advanced.publication-type.article": "articles",
    "search.form.advanced.publication-type.chapter": "chapters in collective works",
    "search.form.advanced.publication-type.book": "monographs and collective works",
    "search.form.advanced.open-resources.label": "Search only in open resources",
    "search.form.advanced.contributors.label": "Contributors",
    "search.form.advanced.contributors.placeholder": "name, surname or ORCID",
    "search.form.advanced.published-date.label": "Date of publication between",
    "search.form.advanced.published-date-since.label": "Publication date from",
    "search.form.advanced.published-date-to.label": "Publication date to",
    "search.form.advanced.research-data-link.label": "Link to research data",
    "search.form.advanced.published-date.invalid": "Enter date in YYYY-MM-DD, YYYY-MM or YYYY format",
    "search.form.advanced.published-date.order.invalid": "End date must be the same or later than the start date",
    "search.form.advanced.published-date.placeholder": "YYYY, YYYY-MM or YYYY-MM-DD",
    "search.form.advanced.expandable-panel.toggle.label": "Advanced search",
    "search.form.advanced.language.label": "Main language of publication",
    "search.form.advanced.licenses.label": "Freedom of use",
    "search.form.advanced.licenses.allcc": "All Creative Commons licenses",
    "search.form.advanced.scientific-disciplines.label": "Scientific disciplines",
    "search.form.advanced.keywords.label": "Keywords",
    "search.form.advanced.publishing-companies.label": "Publishers",
    "search.form.advanced.journals.label": "Journals",
    "search.form.advanced.pcj.label.full": "Publisher, Journal, ISSN",
    "search.form.advanced.pcj.label.publisher": "Publisher:",
    "search.form.advanced.pcj.issn.prefix": "ISSN",
    "search.form.advanced.pcj.eissn.prefix": "eISSN",
    "search.form.advanced.pcj.publishing-companies.error.max": "Maximum number of publishers: 30",
    "search.form.advanced.pcj.journals.error.max": "Maximum number of journals: 30",
    "spinner.sr-only": "Loading...",
    "modal.close.button.label": "Close",
    "link.externalLink.label": ", Opens in new tab",
    "icon.externalLink.label": ", Opens in new tab",
    "icon.download.label": "Download",
    "pbn.export.button.label": "Export to PBN",
    "error.general.title": "Error",
    "error.general.header": "Error.",
    "error.notfound.title": "Page not found",
    "error.notfound.header": "Page not found.",
    "error.accessdenied.header": "Access denied.",
    "error.button.mainpage": "Return to homepage",
    "safemail.placeholder.message": "Reveal e-mail",
    "logout.button": "Logout",
    "logout.header.loggedOut": "Logged out",
    "react-select.aria.live.option": "option",
    "react-select.aria.live.options": "options",
    "react-select.aria.live.guidance.menu.choose": "Use Up and Down to choose options",
    "react-select.aria.live.guidance.menu.select": "press Enter to select the currently focused option",
    "react-select.aria.live.guidance.menu.exit": "press Escape to exit the menu",
    "react-select.aria.live.guidance.menu.select-and-exit": "press Tab to select the option and exit the menu",
    "react-select.aria.live.guidance.input.is-focused": "is focused",
    "react-select.aria.live.guidance.input.refine": "type to refine list",
    "react-select.aria.live.guidance.input.open-menu": "press Down to open the menu",
    "react-select.aria.live.guidance.input.focus-selected": "press left to focus selected values",
    "react-select.aria.live.guidance.value": "Use left and right to toggle between focused values, press Backspace to remove the currently focused value",
    "react-select.aria.live.on-change.deselected": "deselected",
    "react-select.aria.live.on-change.clear": "All selected options have been cleared.",
    "react-select.aria.live.on-change.initial-input-focus.selected": "selected",
    "react-select.aria.live.on-change.initial-input-focus.selected.plural": "selected",
    "react-select.aria.live.on-change.select-option.disabled": "is disabled. Select another option.",
    "react-select.aria.live.on-change.select-option.selected": "selected.",
    "react-select.aria.live.on-focus.of": "of",
    "react-select.aria.live.on-focus.value.value": "value",
    "react-select.aria.live.on-focus.value.focused": "focused",
    "react-select.aria.live.on-focus.menu.disabled": "disabled",
    "react-select.aria.live.on-focus.menu.selected": "selected",
    "react-select.aria.live.on-focus.menu.focused": "focused",
    "react-select.aria.live.on-filter": "for search term",
    "dictionary.citation.apa": "APA",
    "dictionary.citation.mla": "MLA",
    "dictionary.citation.ama": "AMA",
    "dictionary.citation.nlm": "NLM",
    "dictionary.citation.iso690": "ISO 690",
    "dictionary.journal_external_id_type.issn": "ISSN",
    "dictionary.journal_external_id_type.eissn": "e-ISSN",
    "dictionary.journal_external_id_type.series_issn": "series ISSN",
    "dictionary.journal_external_id_type.series_eissn": "series e-ISSN",
    "dictionary.journal_external_id_type.doi": "DOI",
    "dictionary.journal_additional_description_type.editorial_board": "Editorial Board",
    "dictionary.journal_additional_description_type.scientific_council": "Scientific Council",
    "dictionary.journal_additional_description_type.for_authors_and_reviewers": "For authors and reviewers",
    "dictionary.journal_additional_description_type.publishing_ethics": "Publisher ethics",
    "dictionary.journal_additional_description_type.open_access_policy": "Open Access Policy",
    "dictionary.journal_title_type.subtitle": "Subtitle",
    "dictionary.journal_title_type.romanization": "Romanization",
    "dictionary.journal_title_type.alternative": "Alternative title",
    "dictionary.journal_title_type.abbreviation": "Title abbreviation",
    "dictionary.journal_title_type.series": "Publishing series",
    "dictionary.journal_database_type.agro": "AGRO (journals in natural and agricultural sciences)",
    "dictionary.journal_database_type.baz_ekon": "BazEkon (journals in economic sciences)",
    "dictionary.journal_database_type.baz_tech": "BazTech (journals in engineering and technology and exact sciences)",
    "dictionary.journal_database_type.cejsh": "CEJSH (journals in humanities, social sciences, theology and arts)",
    "dictionary.journal_database_type.dml_pl": "DML-PL (journals in mathematics)",
    "dictionary.journal_database_type.psjd": "PSJD (journals in medical and health sciences)",
    "dictionary.journal_score_type.mnisw_score": "Current ministerial scoring",
    "dictionary.journal_score_type.snip": "SNIP",
    "dictionary.journal_score_type.sjr": "SJR",
    "dictionary.journal_score_type.impact_factor": "Impact Factor",
    "dictionary.journal_score_type.impact_factor_5_years": "5-year Impact Factor",
    "dictionary.journal_score_type.no_html.mnisw_score": "Current ministerial scoring",
    "dictionary.journal_score_type.no_html.snip": "SNIP",
    "dictionary.journal_score_type.no_html.sjr": "SJR",
    "dictionary.journal_score_type.no_html.impact_factor": "Impact Factor",
    "dictionary.journal_score_type.no_html.impact_factor_5_years": "5-year Impact Factor",
    "dictionary.journal_issue_frequency_type.daily": "Daily",
    "dictionary.journal_issue_frequency_type.weekly": "Weekly",
    "dictionary.journal_issue_frequency_type.biweekly": "Biweekly",
    "dictionary.journal_issue_frequency_type.monthly": "Monthly",
    "dictionary.journal_issue_frequency_type.bimonthly": "Bimonthly",
    "dictionary.journal_issue_frequency_type.quarterly": "Quarterly",
    "dictionary.journal_issue_frequency_type.semiannually": "Semi-annually",
    "dictionary.journal_issue_frequency_type.annually": "Annually",
    "dictionary.journal_issue_frequency_type.irregularly_published": "Irregular publication",
    "dictionary.article_external_id_type.doi": "DOI",
    "dictionary.article_external_id_type.pubmed_id": "PMID",
    "dictionary.book_external_id_type.doi": "DOI",
    "dictionary.book_external_id_type.pubmed_id": "PMID",
    "dictionary.book_external_id_type.isbn": "ISBN",
    "dictionary.chapter_external_id_type.doi": "DOI",
    "dictionary.chapter_external_id_type.pubmed_id": "PMID",
    "dictionary.publication_date_type.received": "Received",
    "dictionary.publication_date_type.published": "Published",
    "dictionary.publication_date_type.accepted": "Accepted",
    "dictionary.publication_date_type.released_online": "Published online",
    "dictionary.article_type.original_scientific_text": "Original scientific text",
    "dictionary.article_type.review": "Review",
    "dictionary.article_type.translation": "Critical translation",
    "dictionary.article_type.other_scientific_publication": "Other scientific publication",
    "dictionary.simple_book_type.original_scientific_text": "Original scientific text",
    "dictionary.simple_book_type.translation": "Critical translation",
    "dictionary.simple_book_type.scientific_edition_of_source_material": "Scientific edition of source materials",
    "dictionary.simple_book_type.other_scientific_publication": "Other scientific publication",
    "dictionary.collective_work_type.original_scientific_text": "Original scientific text",
    "dictionary.collective_work_type.other_scientific_publication": "Other scientific publication",
    "dictionary.chapter_type.original_scientific_text": "Original scientific text",
    "dictionary.chapter_type.review": "Review",
    "dictionary.chapter_type.foreword": "Introduction/Preface",
    "dictionary.chapter_type.afterword": "Epilogue",
    "dictionary.chapter_type.translation": "Critical translation",
    "dictionary.chapter_type.scientific_edition_of_source_material": "Scientific edition of source materials",
    "dictionary.chapter_type.index": "Index",
    "dictionary.chapter_type.other_scientific_publication": "Other scientific publication",
    "dictionary.contributor_role.author": "Author",
    "dictionary.contributor_role.editor": "Editor",
    "dictionary.contributor_role.scientific_editor": "Scientific editor",
    "dictionary.contributor_role.reviewer": "Reviewer",
    "dictionary.contributor_role.translator": "Translator",
    "dictionary.contributor_role.authors": "Authors",
    "dictionary.contributor_role.editors": "Editors",
    "dictionary.contributor_role.scientific_editors": "Scientific editors",
    "dictionary.contributor_role.reviewers": "Reviewers",
    "dictionary.contributor_role.translators": "Translators",
    "dictionary.contributor_role.author.corresponding": "Corresponding author",
    "dictionary.contributor_role.editor.corresponding": "Corresponding editor",
    "dictionary.contributor_role.scientific_editor.corresponding": "Corresponding scientific editor",
    "dictionary.contributor_role.reviewer.corresponding": "Corresponding reviewer",
    "dictionary.contributor_role.translator.corresponding": "Corresponding translator",
    "dictionary.contributor_role.authors.corresponding": "Corresponding authors",
    "dictionary.contributor_role.editors.corresponding": "Corresponding editors",
    "dictionary.contributor_role.scientific_editors.corresponding": "Corresponding scientific editor",
    "dictionary.contributor_role.reviewers.corresponding": "Corresponding reviewers",
    "dictionary.contributor_role.translators.corresponding": "Corresponding translators",
    "dictionary.language.POL": "Polish",
    "dictionary.language.ENG": "English",
    "dictionary.language.FRA": "French",
    "dictionary.language.DEU": "German",
    "dictionary.language.RUS": "Russian",
    "dictionary.language.ABK": "Abkhazian",
    "dictionary.language.AAR": "Afar",
    "dictionary.language.AFR": "Afrikaans",
    "dictionary.language.AYM": "Aymara",
    "dictionary.language.SQI": "Albanian",
    "dictionary.language.AMH": "Amharic",
    "dictionary.language.ARA": "Arabic",
    "dictionary.language.ASM": "Assamese",
    "dictionary.language.AZE": "Azerbaijani",
    "dictionary.language.EUS": "Basque",
    "dictionary.language.BAK": "Bashkir",
    "dictionary.language.BEN": "Bengali",
    "dictionary.language.BEL": "Belarusian",
    "dictionary.language.BIH": "Bihari languages",
    "dictionary.language.MYA": "Burmese",
    "dictionary.language.BIS": "Bislama",
    "dictionary.language.BRE": "Breton",
    "dictionary.language.BUL": "Bulgarian",
    "dictionary.language.ZHO": "Chinese",
    "dictionary.language.HRV": "Croatian",
    "dictionary.language.CES": "Czech",
    "dictionary.language.DAN": "Danish",
    "dictionary.language.DZO": "Dzongkha",
    "dictionary.language.EPO": "Esperanto",
    "dictionary.language.EST": "Estonian",
    "dictionary.language.FAO": "Faroese",
    "dictionary.language.FIJ": "Fijian",
    "dictionary.language.FIN": "Finnish",
    "dictionary.language.FRY": "Western Frisian",
    "dictionary.language.GLA": "Gaelic",
    "dictionary.language.GLG": "Galician",
    "dictionary.language.ELL": "Greek, Modern (1453-)",
    "dictionary.language.KAL": "Kalaallisut",
    "dictionary.language.KAT": "Georgian",
    "dictionary.language.GRN": "Guarani",
    "dictionary.language.GUJ": "Gujarati",
    "dictionary.language.HAU": "Hausa",
    "dictionary.language.IKU": "Inuktitut",
    "dictionary.language.HIN": "Hindi",
    "dictionary.language.SPA": "Spanish",
    "dictionary.language.NLD": "Dutch",
    "dictionary.language.INA": "Interlingua (International Auxiliary Language Association)",
    "dictionary.language.ILE": "Interlingue",
    "dictionary.language.IPK": "Inupiaq",
    "dictionary.language.GLE": "Irish",
    "dictionary.language.ISL": "Icelandic",
    "dictionary.language.JPN": "Japanese",
    "dictionary.language.JAV": "Javanese",
    "dictionary.language.YOR": "Yoruba",
    "dictionary.language.KAN": "Kannada",
    "dictionary.language.KAS": "Kashmiri",
    "dictionary.language.CAT": "Catalan",
    "dictionary.language.KAZ": "Kazakh",
    "dictionary.language.QUE": "Quechua",
    "dictionary.language.KHM": "Khmer",
    "dictionary.language.KIR": "Kirghiz",
    "dictionary.language.KOR": "Korean",
    "dictionary.language.COS": "Corsican",
    "dictionary.language.KUR": "Kurdish",
    "dictionary.language.LAT": "Latin",
    "dictionary.language.LAO": "Lao",
    "dictionary.language.LIN": "Lingala",
    "dictionary.language.LIT": "Lithuanian",
    "dictionary.language.LAV": "Latvian",
    "dictionary.language.MKD": "Macedonian",
    "dictionary.language.MLG": "Malagasy",
    "dictionary.language.MAL": "Malayalam",
    "dictionary.language.MSA": "Malay",
    "dictionary.language.MLT": "Maltese",
    "dictionary.language.MRI": "Maori",
    "dictionary.language.MAR": "Marathi",
    "dictionary.language.MON": "Mongolian",
    "dictionary.language.NAU": "Nauru",
    "dictionary.language.NEP": "Nepali",
    "dictionary.language.NOR": "Norwegian",
    "dictionary.language.ORI": "Oriya",
    "dictionary.language.HYE": "Armenian",
    "dictionary.language.ORM": "Oromo",
    "dictionary.language.PUS": "Pashto",
    "dictionary.language.PAN": "Punjabi",
    "dictionary.language.FAS": "Persian",
    "dictionary.language.POR": "Portuguese",
    "dictionary.language.OCI": "Occitan (post 1500)",
    "dictionary.language.ROH": "Romansh",
    "dictionary.language.KIN": "Kinyarwanda",
    "dictionary.language.RON": "Romanian",
    "dictionary.language.RUN": "Rundi",
    "dictionary.language.SMO": "Samoan",
    "dictionary.language.SAG": "Sango",
    "dictionary.language.SAN": "Sanskrit",
    "dictionary.language.SRP": "Serbian",
    "dictionary.language.SNA": "Shona",
    "dictionary.language.SND": "Sindhi",
    "dictionary.language.SLK": "Slovak",
    "dictionary.language.SLV": "Slovenian",
    "dictionary.language.SOM": "Somali",
    "dictionary.language.SOT": "Sotho, Southern",
    "dictionary.language.SWA": "Swahili",
    "dictionary.language.SSW": "Swati",
    "dictionary.language.SUN": "Sundanese",
    "dictionary.language.SIN": "Sinhala",
    "dictionary.language.SWE": "Swedish",
    "dictionary.language.TGK": "Tajik",
    "dictionary.language.TGL": "Tagalog",
    "dictionary.language.THA": "Thai",
    "dictionary.language.TAM": "Tamil",
    "dictionary.language.TAT": "Tatar",
    "dictionary.language.TEL": "Telugu",
    "dictionary.language.TIR": "Tigrinya",
    "dictionary.language.TON": "Tonga (Tonga Islands)",
    "dictionary.language.TSO": "Tsonga",
    "dictionary.language.TSN": "Tswana",
    "dictionary.language.TUR": "Turkish",
    "dictionary.language.TUK": "Turkmen",
    "dictionary.language.TWI": "Twi",
    "dictionary.language.BOD": "Tibetan",
    "dictionary.language.UKR": "Ukrainian",
    "dictionary.language.URD": "Urdu",
    "dictionary.language.UZB": "Uzbek",
    "dictionary.language.VOL": "Volapük",
    "dictionary.language.CYM": "Welsh",
    "dictionary.language.HUN": "Hungarian",
    "dictionary.language.VIE": "Vietnamese",
    "dictionary.language.ITA": "Italian",
    "dictionary.language.WOL": "Wolof",
    "dictionary.language.XHO": "Xhosa",
    "dictionary.language.ZUL": "Zulu",
    "dictionary.language.ACE": "Achinese",
    "dictionary.language.ACH": "Acoli",
    "dictionary.language.ADA": "Adangme",
    "dictionary.language.ADY": "Adyghe",
    "dictionary.language.AFH": "Afrihili",
    "dictionary.language.AFA": "Afro-Asiatic languages",
    "dictionary.language.AIN": "Ainu",
    "dictionary.language.AKA": "Akan",
    "dictionary.language.AKK": "Akkadian",
    "dictionary.language.ALE": "Aleut",
    "dictionary.language.ALG": "Algonquian languages",
    "dictionary.language.TUT": "Altaic languages",
    "dictionary.language.ANP": "Angika",
    "dictionary.language.APA": "Apache languages",
    "dictionary.language.ARG": "Aragonese",
    "dictionary.language.ARP": "Arapaho",
    "dictionary.language.ARW": "Arawak",
    "dictionary.language.RUP": "Aromanian",
    "dictionary.language.ART": "Artificial languages",
    "dictionary.language.AST": "Asturian",
    "dictionary.language.ATH": "Athapascan languages",
    "dictionary.language.AUS": "Australian languages",
    "dictionary.language.MAP": "Austronesian languages",
    "dictionary.language.AVA": "Avaric",
    "dictionary.language.AVE": "Avestan",
    "dictionary.language.AWA": "Awadhi",
    "dictionary.language.BAN": "Balinese",
    "dictionary.language.BAT": "Baltic languages",
    "dictionary.language.BAL": "Baluchi",
    "dictionary.language.BAM": "Bambara",
    "dictionary.language.BAI": "Bamileke languages",
    "dictionary.language.BAD": "Banda languages",
    "dictionary.language.BNT": "Bantu languages",
    "dictionary.language.BAS": "Basa",
    "dictionary.language.BTK": "Batak languages",
    "dictionary.language.BEJ": "Beja",
    "dictionary.language.BEM": "Bemba",
    "dictionary.language.BER": "Berber languages",
    "dictionary.language.BHO": "Bhojpuri",
    "dictionary.language.BIK": "Bikol",
    "dictionary.language.BIN": "Bini",
    "dictionary.language.BYN": "Blin",
    "dictionary.language.ZBL": "Blissymbols",
    "dictionary.language.NOB": "Bokmål, Norwegian",
    "dictionary.language.BOS": "Bosnian",
    "dictionary.language.BRA": "Braj",
    "dictionary.language.BUG": "Buginese",
    "dictionary.language.BUA": "Buriat",
    "dictionary.language.CAD": "Caddo",
    "dictionary.language.CAU": "Caucasian languages",
    "dictionary.language.CEB": "Cebuano",
    "dictionary.language.CEL": "Celtic languages",
    "dictionary.language.CAI": "Central American Indian languages",
    "dictionary.language.CHG": "Chagatai",
    "dictionary.language.CMC": "Chamic languages",
    "dictionary.language.CHA": "Chamorro",
    "dictionary.language.CHE": "Chechen",
    "dictionary.language.CHR": "Cherokee",
    "dictionary.language.CHY": "Cheyenne",
    "dictionary.language.CHB": "Chibcha",
    "dictionary.language.NYA": "Chichewa",
    "dictionary.language.CHN": "Chinook jargon",
    "dictionary.language.CHP": "Chipewyan",
    "dictionary.language.CHO": "Choctaw",
    "dictionary.language.CHU": "Church Slavic",
    "dictionary.language.CHK": "Chuukese",
    "dictionary.language.CHV": "Chuvash",
    "dictionary.language.NWC": "Classical Newari",
    "dictionary.language.SYC": "Classical Syriac",
    "dictionary.language.COP": "Coptic",
    "dictionary.language.COR": "Cornish",
    "dictionary.language.CRE": "Cree",
    "dictionary.language.MUS": "Creek",
    "dictionary.language.CRP": "Creoles and pidgins",
    "dictionary.language.CPE": "Creoles and pidgins, English-based",
    "dictionary.language.CPF": "Creoles and pidgins, French-based",
    "dictionary.language.CPP": "Creoles and pidgins, Portuguese-based",
    "dictionary.language.CRH": "Crimean Tatar",
    "dictionary.language.CUS": "Cushitic languages",
    "dictionary.language.DAK": "Dakota",
    "dictionary.language.DAR": "Dargwa",
    "dictionary.language.DEL": "Delaware",
    "dictionary.language.DIN": "Dinka",
    "dictionary.language.DIV": "Divehi",
    "dictionary.language.DOI": "Dogri",
    "dictionary.language.DGR": "Dogrib",
    "dictionary.language.DRA": "Dravidian languages",
    "dictionary.language.DUA": "Duala",
    "dictionary.language.DUM": "Dutch, Middle (ca. 1050-1350)",
    "dictionary.language.DYU": "Dyula",
    "dictionary.language.FRS": "Eastern Frisian",
    "dictionary.language.EFI": "Efik",
    "dictionary.language.EGY": "Egyptian (Ancient)",
    "dictionary.language.EKA": "Ekajuk",
    "dictionary.language.ELX": "Elamite",
    "dictionary.language.ENM": "English, Middle (1100-1500)",
    "dictionary.language.ANG": "English, Old (ca. 450-1100)",
    "dictionary.language.MYV": "Erzya",
    "dictionary.language.EWE": "Ewe",
    "dictionary.language.EWO": "Ewondo",
    "dictionary.language.FAN": "Fang",
    "dictionary.language.FAT": "Fanti",
    "dictionary.language.FIL": "Filipino",
    "dictionary.language.FIU": "Finno-Ugrian languages",
    "dictionary.language.FON": "Phone",
    "dictionary.language.FRM": "French, Middle (ca. 1400-1600)",
    "dictionary.language.FRO": "French, Old (842-ca. 1400)",
    "dictionary.language.FUR": "Friulian",
    "dictionary.language.FUL": "Fulah",
    "dictionary.language.GAA": "Ga",
    "dictionary.language.CAR": "Galibi Carib",
    "dictionary.language.LUG": "Ganda",
    "dictionary.language.GAY": "Gayo",
    "dictionary.language.GBA": "Gbaya",
    "dictionary.language.GEZ": "Geez",
    "dictionary.language.GMH": "German, Middle High (ca. 1050-1500)",
    "dictionary.language.GOH": "German, Old High (ca. 750-1050)",
    "dictionary.language.GEM": "Germanic languages",
    "dictionary.language.GIL": "Gilbertese",
    "dictionary.language.GON": "Gondi",
    "dictionary.language.GOR": "Gorontalo",
    "dictionary.language.GOT": "Gothic",
    "dictionary.language.GRB": "Grebo",
    "dictionary.language.GRC": "Greek, Ancient (to 1453)",
    "dictionary.language.GWI": "Gwich'in",
    "dictionary.language.HAI": "Haida",
    "dictionary.language.HAT": "Haitian",
    "dictionary.language.HAW": "Hawaiian",
    "dictionary.language.HEB": "Hebrew",
    "dictionary.language.HER": "Herero",
    "dictionary.language.HIL": "Hiligaynon",
    "dictionary.language.HIM": "Himachali languages",
    "dictionary.language.HMO": "Hiri Motu",
    "dictionary.language.HIT": "Hittite",
    "dictionary.language.HMN": "Hmong",
    "dictionary.language.HUP": "Hupa",
    "dictionary.language.IBA": "Iban",
    "dictionary.language.IDO": "Ido",
    "dictionary.language.IBO": "Igbo",
    "dictionary.language.IJO": "Ijo languages",
    "dictionary.language.ILO": "Iloko",
    "dictionary.language.SMN": "Inari Sami",
    "dictionary.language.INC": "Indic languages",
    "dictionary.language.INE": "Indo-European languages",
    "dictionary.language.IND": "Indonesian",
    "dictionary.language.INH": "Ingush",
    "dictionary.language.IRA": "Iranian languages",
    "dictionary.language.MGA": "Irish, Middle (900-1200)",
    "dictionary.language.SGA": "Irish, Old (to 900)",
    "dictionary.language.IRO": "Iroquoian languages",
    "dictionary.language.JRB": "Judeo-Arabic",
    "dictionary.language.JPR": "Judeo-Persian",
    "dictionary.language.KBD": "Kabardian",
    "dictionary.language.KAB": "Kabyle",
    "dictionary.language.KAC": "Kachin",
    "dictionary.language.XAL": "Kalmyk",
    "dictionary.language.KAM": "Kamba",
    "dictionary.language.KAU": "Kanuri",
    "dictionary.language.KAA": "Kara-Kalpak",
    "dictionary.language.KRC": "Karachay-Balkar",
    "dictionary.language.KRL": "Karelian",
    "dictionary.language.KAR": "Karen languages",
    "dictionary.language.CSB": "Kashubian",
    "dictionary.language.KAW": "Kawi",
    "dictionary.language.KHA": "Khasi",
    "dictionary.language.KHI": "Khoisan languages",
    "dictionary.language.KHO": "Khotanese",
    "dictionary.language.KIK": "Kikuyu",
    "dictionary.language.KMB": "Kimbundu",
    "dictionary.language.TLH": "Klingon",
    "dictionary.language.KOM": "Komi",
    "dictionary.language.KON": "Kongo",
    "dictionary.language.KOK": "Konkani",
    "dictionary.language.KOS": "Kosraean",
    "dictionary.language.KPE": "Kpelle",
    "dictionary.language.KRO": "Kru languages",
    "dictionary.language.KUA": "Kuanyama",
    "dictionary.language.KUM": "Kumyk",
    "dictionary.language.KRU": "Kurukh",
    "dictionary.language.KUT": "Kutenai",
    "dictionary.language.LAD": "Ladino",
    "dictionary.language.LAH": "Lahnda",
    "dictionary.language.LAM": "Lamba",
    "dictionary.language.DAY": "Land Dayak languages",
    "dictionary.language.LEZ": "Lezghian",
    "dictionary.language.LIM": "Limburgan",
    "dictionary.language.JBO": "Lojban",
    "dictionary.language.NDS": "Low German",
    "dictionary.language.DSB": "Lower Sorbian",
    "dictionary.language.LOZ": "Lozi",
    "dictionary.language.LUB": "Luba-Katanga",
    "dictionary.language.LUA": "Luba-Lulua",
    "dictionary.language.LUI": "Luiseno",
    "dictionary.language.SMJ": "Lule Sami",
    "dictionary.language.LUN": "Lunda",
    "dictionary.language.LUO": "Luo (Kenya and Tanzania)",
    "dictionary.language.LUS": "Lushai",
    "dictionary.language.LTZ": "Luxembourgish",
    "dictionary.language.MAD": "Madurese",
    "dictionary.language.MAG": "Magahi",
    "dictionary.language.MAI": "Maithili",
    "dictionary.language.MAK": "Makasar",
    "dictionary.language.MNC": "Manchu",
    "dictionary.language.MDR": "Mandar",
    "dictionary.language.MAN": "Mandingo",
    "dictionary.language.MNI": "Manipuri",
    "dictionary.language.MNO": "Manobo languages",
    "dictionary.language.GLV": "Manx",
    "dictionary.language.ARN": "Mapudungun",
    "dictionary.language.CHM": "Mari",
    "dictionary.language.MAH": "Marshallese",
    "dictionary.language.MWR": "Marwari",
    "dictionary.language.MAS": "Masai",
    "dictionary.language.MYN": "Mayan languages",
    "dictionary.language.MEN": "Mende",
    "dictionary.language.MIC": "Mi'kmaq",
    "dictionary.language.MIN": "Minangkabau",
    "dictionary.language.MWL": "Mirandese",
    "dictionary.language.MOH": "Mohawk",
    "dictionary.language.MDF": "Moksha",
    "dictionary.language.MKH": "Mon-Khmer languages",
    "dictionary.language.LOL": "Mongo",
    "dictionary.language.MOS": "Mossi",
    "dictionary.language.MUN": "Munda languages",
    "dictionary.language.NQO": "N'Ko",
    "dictionary.language.NAH": "Nahuatl languages",
    "dictionary.language.NAV": "Navajo",
    "dictionary.language.NDE": "Ndebele, North",
    "dictionary.language.NBL": "Ndebele, South",
    "dictionary.language.NDO": "Ndonga",
    "dictionary.language.NAP": "Neapolitan",
    "dictionary.language.NEW": "Nepal Bhasa",
    "dictionary.language.NIA": "Nias",
    "dictionary.language.NIC": "Niger-Kordofanian languages",
    "dictionary.language.SSA": "Nilo-Saharan languages",
    "dictionary.language.NIU": "Niuean",
    "dictionary.language.NOG": "Nogai",
    "dictionary.language.NON": "Norse, Old",
    "dictionary.language.NAI": "North American Indian languages",
    "dictionary.language.FRR": "Northern Frisian",
    "dictionary.language.SME": "Northern Sami",
    "dictionary.language.NNO": "Norwegian Nynorsk",
    "dictionary.language.NUB": "Nubian languages",
    "dictionary.language.NYM": "Nyamwezi",
    "dictionary.language.NYN": "Nyankole",
    "dictionary.language.NYO": "Nyoro",
    "dictionary.language.NZI": "Nzima",
    "dictionary.language.ARC": "Official Aramaic (700-300 BCE)",
    "dictionary.language.OJI": "Ojibwa",
    "dictionary.language.OSA": "Osage",
    "dictionary.language.OSS": "Ossetian",
    "dictionary.language.OTO": "Otomian languages",
    "dictionary.language.PAL": "Pahlavi",
    "dictionary.language.PAU": "Palauan",
    "dictionary.language.PLI": "Pali",
    "dictionary.language.PAM": "Pampanga",
    "dictionary.language.PAG": "Pangasinan",
    "dictionary.language.PAP": "Papiamento",
    "dictionary.language.PAA": "Papuan languages",
    "dictionary.language.NSO": "Pedi",
    "dictionary.language.PEO": "Persian, Old (ca. 600-400 BC)",
    "dictionary.language.PHI": "Philippine languages",
    "dictionary.language.PHN": "Phoenician",
    "dictionary.language.PON": "Pohnpeian",
    "dictionary.language.PRA": "Prakrit languages",
    "dictionary.language.PRO": "Provençal, Old (to 1500)",
    "dictionary.language.RAJ": "Rajasthani",
    "dictionary.language.RAP": "Rapanui",
    "dictionary.language.RAR": "Rarotongan",
    "dictionary.language.ROA": "Romance languages",
    "dictionary.language.ROM": "Romany",
    "dictionary.language.SAL": "Salishan languages",
    "dictionary.language.SAM": "Samaritan Aramaic",
    "dictionary.language.SMI": "Sami languages",
    "dictionary.language.SAD": "Sandawe",
    "dictionary.language.SAT": "Santali",
    "dictionary.language.SRD": "Sardinian",
    "dictionary.language.SAS": "Sasak",
    "dictionary.language.SCO": "Scots",
    "dictionary.language.SEL": "Selkup",
    "dictionary.language.SEM": "Semitic languages",
    "dictionary.language.SRR": "Serer",
    "dictionary.language.SHN": "Shan",
    "dictionary.language.III": "Sichuan Yi",
    "dictionary.language.SCN": "Sicilian",
    "dictionary.language.SID": "Sidamo",
    "dictionary.language.SGN": "Sign Languages",
    "dictionary.language.BLA": "Siksika",
    "dictionary.language.SIT": "Sino-Tibetan languages",
    "dictionary.language.SIO": "Siouan languages",
    "dictionary.language.SMS": "Skolt Sami",
    "dictionary.language.DEN": "Slave (Athapascan)",
    "dictionary.language.SLA": "Slavic languages",
    "dictionary.language.SOG": "Sogdian",
    "dictionary.language.SON": "Songhai languages",
    "dictionary.language.SNK": "Soninke",
    "dictionary.language.WEN": "Sorbian languages",
    "dictionary.language.SAI": "South American Indian languages",
    "dictionary.language.ALT": "Southern Altai",
    "dictionary.language.SMA": "Southern Sami",
    "dictionary.language.SRN": "Sranan Tongo",
    "dictionary.language.SUK": "Sukuma",
    "dictionary.language.SUX": "Sumerian",
    "dictionary.language.SUS": "Susu",
    "dictionary.language.GSW": "Swiss German",
    "dictionary.language.SYR": "Syriac",
    "dictionary.language.TAH": "Tahitian",
    "dictionary.language.TAI": "Tai languages",
    "dictionary.language.TMH": "Tamashek",
    "dictionary.language.TER": "Tereno",
    "dictionary.language.TET": "Tetum",
    "dictionary.language.TIG": "Tigre",
    "dictionary.language.TEM": "Timne",
    "dictionary.language.TIV": "Tiv",
    "dictionary.language.TLI": "Tlingit",
    "dictionary.language.TPI": "Tok Pisin",
    "dictionary.language.TKL": "Tokelau",
    "dictionary.language.TOG": "Tonga (Nyasa)",
    "dictionary.language.TSI": "Tsimshian",
    "dictionary.language.TUM": "Tumbuka",
    "dictionary.language.TUP": "Tupi languages",
    "dictionary.language.OTA": "Turkish, Ottoman (1500-1928)",
    "dictionary.language.TVL": "Tuvalu",
    "dictionary.language.TYV": "Tuvinian",
    "dictionary.language.UDM": "Udmurt",
    "dictionary.language.UGA": "Ugaritic",
    "dictionary.language.UIG": "Uighur",
    "dictionary.language.UMB": "Umbundu",
    "dictionary.language.HSB": "Upper Sorbian",
    "dictionary.language.VAI": "Vai",
    "dictionary.language.VEN": "Venda",
    "dictionary.language.VOT": "Votic",
    "dictionary.language.WAK": "Wakashan languages",
    "dictionary.language.WLN": "Walloon",
    "dictionary.language.WAR": "Waray",
    "dictionary.language.WAS": "Washo",
    "dictionary.language.WAL": "Wolaitta",
    "dictionary.language.SAH": "Yakut",
    "dictionary.language.YAO": "Yao",
    "dictionary.language.YAP": "Yapese",
    "dictionary.language.YID": "Yiddish",
    "dictionary.language.YPK": "Yupik languages",
    "dictionary.language.ZND": "Zande languages",
    "dictionary.language.ZAP": "Zapotec",
    "dictionary.language.ZZA": "Zaza",
    "dictionary.language.ZEN": "Zenaga",
    "dictionary.language.ZHA": "Zhuang",
    "dictionary.language.ZUN": "Zuni",
    "dictionary.language.MIS": "Uncoded languages",
    "dictionary.language.MUL": "Multiple languages",
    "dictionary.language.UND": "Undetermined",
    "dictionary.language.ZXX": "No linguistic content"
  }
}
